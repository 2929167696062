import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@assist/shared/data';
import { TranslocoModule } from "@ngneat/transloco";

@Component({
  selector: 'nav-not-enabled',
  templateUrl: './not-enabled.component.html',
  styleUrls: ['./not-enabled.component.scss'],
  standalone: true,
  imports: [TranslocoModule]
})
export class NotEnabledComponent  {}
