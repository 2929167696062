import { Component, Inject, OnInit, Optional, ViewEncapsulation } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";
import { Localization, LOCALIZATIONS } from "@cue/translate";
import { SwUpdate } from "@angular/service-worker";
import { DropdownlistComponent, ItemTemplateDirective, ValueTemplateDirective } from "@cue/assist/ui/dropdowns";
import { RouterOutlet } from "@angular/router";

@Component({
  selector: "nav-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    DropdownlistComponent,
    ValueTemplateDirective,
    ItemTemplateDirective,
    RouterOutlet,
    ReactiveFormsModule
  ],
  providers: [

  ]
})
export class AppComponent implements  OnInit {

  public languageItems: Array<{ text: string; flag: string }> = [
    // TOUCH-240 - vypnuti jazyku dokud nejsou spravne preklady
    //{ text: "AR", flag: "ar.svg" },
    { text: "CS", flag: "cs.svg" },
    //{ text: "DE", flag: "ge.svg"},
    { text: "EN", flag: "en.svg" },
    { text: "ES", flag: "es.svg" },
    { text: "DE", flag: "ge.svg" },
    { text: "CA", flag: "ca.svg" },
    { text: "UA", flag: "ua.svg" },
    { text: "FR", flag: "fr.svg" }
    // { text: "NB", flag: "ne.svg"},
    //{ text: "PL", flag: "pl.svg"},
  ];
  currentLanguageControl = new FormControl<{ text: string; flag: string }>(null);
  title = "navigation";

  constructor(
    private swUpdate: SwUpdate,
    public translocoService: TranslocoService,
    @Optional() @Inject(LOCALIZATIONS) private localizations: Localization[][]
  ) {
    this.currentLanguageControl.setValue(this.currentLanguage, {
      emitEvent: false
    });
    this.currentLanguageControl.valueChanges.subscribe((val) => {
      this.currentLanguage = val;
    });
  }

  get currentLanguage(): { text: string; flag: string } {
    const availableLangs: any[] = this.translocoService.getAvailableLangs();
    const langFromLocalStorage = localStorage.getItem("assist-lang");
    if (langFromLocalStorage != undefined) {
      const found = availableLangs.find((x) => x == langFromLocalStorage);
      return this.languageItems.find(
        (x) => x.text == (found ?? "en").toUpperCase()
      )!;
    } else {
      const found = availableLangs.find(
        (x) => x == navigator.language.slice(0, 2)
      );
      return this.languageItems.find(
        (x) => x.text == (found ?? "en").toUpperCase()
      )!;
    }
  }

  set currentLanguage(value: { text: string; flag: string }) {
    const availableLangs: any[] = this.translocoService.getAvailableLangs();
    const found = availableLangs.find((x) => x == value.text.toLowerCase());

    if (found) {
      const translation = this.localizations[0].find(
        (x) => x.language == found
      )!;
      translation.valueGetter()
        .then((x) => {
          localStorage.setItem("assist-lang", found);
          this.translocoService.setTranslation(x, translation.language, {
            merge: false
          });
          this.translocoService.setActiveLang(found);
        });
    }
  }

  ngOnInit() {
    this.translocoService.langChanges$.subscribe(x=>{
      this.currentLanguageControl.setValue(this.currentLanguage, {
        emitEvent: false
      });
    });

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(evt => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            if (confirm("New version available. Load New Version?")) {
              window.location.reload();
            }
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      });
    }
  }
}
