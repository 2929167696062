import { Injectable } from '@angular/core';
import * as localForage from 'localforage';
import { AssistFilterBM, AssistResourceBM, DesignBm } from "@cue/api";
import {ResourceDetail} from "@assist/shared/data";


type FileConfiguration = {
  apiURL: string;
};
type ApiConfiguration = {
  design: DesignBm;
  googleAnalyticsEnabled: boolean,
  googleAnalyticsKey?: string;
  dateFormat?: string;
  timeFormat?: string;
  enabled: boolean;
  infoSettings: {
    capacityVisible: boolean
  },
  filters: AssistFilterBM[];
  enableReservations: boolean;
};

type Configuration = FileConfiguration & ApiConfiguration;

interface ConfigurationCache {
  data: string;
  timestamp: string;
  version: number;

}

const CONFIGURATION_CACHE_KEY = 'ConfigurationCache';

@Injectable()
export class NavigationConfigService {
  private  CURRENT_VERSION = 6;
  private  _config: Configuration;



  get value(): Configuration {
    if (!this._config)
      throw Error(
        'Value of config service is not initialized, are you sure you have called it in initializer / pre bootstrap?'
      );
    return this._config;
  }



  getFiltersFromResource(
    resource: AssistResourceBM | ResourceDetail
  ): { name: string; value: any; dataTypeId: number }[] {
    const resourceTypeId = resource.resourceTypeId;
    const filters = this.value.filters.filter(
      (x) => x.resourceTypeId === resourceTypeId
    );
    const computed = filters.map((filter) => {
      const properties = resource.properties.filter(
        (property) => property.propertyId === filter.id
      );
      let value: any = null;
      switch (filter.dataTypeId) {
        case 1: {
          value = properties.length > 0 ? properties[0].valueBoolean : null;
          break;
        }
        case 2: {
          value = properties.length > 0 ? properties[0].valueNumber : null;
          break;
        }
        case 3: {
          value = properties.length > 0 ? properties[0].valueDecimal : null;
          break;
        }
        case 4: {
          value = properties.length > 0 ? properties[0].valueText ?? '' : null;
          break;
        }
        case 5: {
          value = filter.choices
            .filter(
              (choice) =>
                properties.find((p) =>
                  p.valueChoiceIds.find((vch) => vch === choice.key)
                ) != null
            )
            .map((x) => ({
              title: x.value,
              imageUrl: x.imageUrl
            }));
          break;
        }
        default:
          value = null;
          break;
      }

      return {
        name: filter.name,
        value: value,
        dataTypeId: filter.dataTypeId,
      };
    });

    return computed.filter(
      (c) => !Array.isArray(c.value) || c.value.length > 0
    );
  }

  getEnumerableFiltersByResourceTypeId(
    resource: AssistResourceBM | ResourceDetail,
  ) {
    return this.getFiltersFromResource(resource).filter(
      (x) => x.dataTypeId === 5
    );
  }

  getSimpleFiltersByResourceTypeId(
    resource: AssistResourceBM | ResourceDetail ,
    ) {
    return this.getFiltersFromResource(resource).filter(
      (x) => x.dataTypeId !== 5
    );
  }


   prebootstrapLoad(): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      let cachedConfiguration: ConfigurationCache;
      request.addEventListener('error', (e) => {
        reject('Cannot load config file.');
      });
      request.addEventListener('load', (_) => {
        if (request.status === 200) {
          try {
            const config = JSON.parse(request.responseText) as Configuration;
            const request2 = new XMLHttpRequest();
            request2.addEventListener('error', (e) => {
              reject('Cannot connect to API server, check if it is available');
            });
            request2.addEventListener('load', () => {
              if (request2.status !== 200 && request2.status !== 204) {
                reject(request2.statusText);
              }
              let response: ApiConfiguration = null;
              if (request2.status === 204) {
                response = JSON.parse(
                  cachedConfiguration.data
                ) as ApiConfiguration;
              }

              if (request2.status === 200) {
                try {
                  let cachedHeader = undefined;
                  try {
                    cachedHeader = request2.getResponseHeader(
                      'CachedConfigurationTimeStamp'
                    );
                  } catch {
                    cachedHeader = null;
                  }
                  if (cachedHeader) {
                    cachedConfiguration = {
                      data: request2.responseText,
                      timestamp: cachedHeader,
                      version: this.CURRENT_VERSION,
                    };
                    localForage.setItem(
                      CONFIGURATION_CACHE_KEY,
                      cachedConfiguration
                    );
                    response = JSON.parse(
                      cachedConfiguration.data
                    ) as ApiConfiguration;
                  } else {
                    localForage.removeItem(CONFIGURATION_CACHE_KEY);
                    response = JSON.parse(request2.responseText);
                  }
                } catch (e) {
                  reject(e);
                }
              }
              if (response == null) {
                localForage.removeItem(CONFIGURATION_CACHE_KEY);
                console.error(
                  'Spatna konfigurace na serveru, /api/navigation/config nevratil zadna data!'
                );
                reject(
                  'Navigation is not configured. Edit the settings in CUE Admin.'
                );
              }

              const configuration = response;
              this._config = {
                ...configuration,
                apiURL: config.apiURL
              };
              resolve(null);
            });
            let configurationUrl = config.apiURL + '/api/navigation/config';

            localForage
              .getItem<ConfigurationCache>(CONFIGURATION_CACHE_KEY)
              .then((cache) => {
                if (
                  cache !== null &&
                  cache.version >= this.CURRENT_VERSION
                ) {
                  // Je nacachovano
                  cachedConfiguration = cache;
                  configurationUrl =
                    configurationUrl +
                    '?cachedTimestamp=' +
                    encodeURIComponent(cache.timestamp);
                }
                request2.open('GET', configurationUrl);
                request2.send();
              });
          } catch (e) {
            reject(e);
          }
        } else {
          reject(request.statusText);
        }
      });
      request.open('GET', '/config.json');
      request.send();
    });
  }



}
