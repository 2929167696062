import { makeEnvironmentProviders } from "@angular/core";
import {
  IGoogleAnalyticsSettings, NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, provideGoogleAnalyticsRouter
} from "@hakimio/ngx-google-analytics";


export function provideGoogleAnalytics(options: {
  keyFactory: (() => string | undefined)
}) {

  const providers = [
    {
      provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useFactory: () => {
        const key = options.keyFactory();
        return {
          ga4TagId: key
        } as IGoogleAnalyticsSettings;
      }
    },
    NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
    provideGoogleAnalyticsRouter()
  ];

  return makeEnvironmentProviders(providers);
}
