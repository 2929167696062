import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class NavigationEnabledGuard implements CanActivate {

  constructor(
    private configService: NavigationConfigService,
    private router: Router
    ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.configService.value.enabled ? true : this.router.parseUrl("/not-enabled");
  }

}
